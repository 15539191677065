import React from 'react';

function Album({ height }) {
  return (
    <div id="album" style={{ height, backgroundColor: 'peachpuff', padding: '20px' }}>
      <p>相簿內容...</p>
    </div>
  );
}

export default Album;
